<template>
  <div class="wrapper">
    <div class="heading">
      <img src="@/assets/img/logo.png" alt="" /> <span>LOGGER</span>
    </div>
    <div class="logger mt-4">
      <p class="mb-4">Вход</p>
      <form class="logger-form">
        <tirscript3-input
          @keyup.enter="onPasswordFocus"
          class="login"
          v-model="loginModel.Email"
          type="text"
          placeholder="Логин"
        />
        <tirscript3-input
          @keyup.enter="onLogin"
          class="password"
          type="password"
          v-model="loginModel.Password"
          placeholder="Пароль"
          :autofocus="autofocusPasswordInput"
        />
        <div class="buttons">
          <router-link to="password-recovery">Забыли пароль?</router-link>
          <tirscript3-button @onClick="onLogin">Войти</tirscript3-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-property-decorator";
import LoginModel from "./LoginModel";
@Options({
  name: "authotization",
})
export default class Logger extends Vue {
  loginModel: LoginModel = new LoginModel();
  autofocusPasswordInput = false;

  // Вход в аакаунт
  onLogin() {
    this.$api.UserService.UserLoginEvent.once((res) => {
      if (res.IsSuccess) {
        this.$router.push({ path: "/" });
      } else {
        this.$notification.error(res.ErrorMessage, "Ошибка");
      }
    });
    this.$api.UserService.UserLogin(this.loginModel);
  }

  onPasswordFocus() {
    this.autofocusPasswordInput = true;
  }
}
</script>

<style lang="less">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgb(34, 26, 58);
  color: white;
  font-size: 20px;
  font-weight: 400;

  .heading {
    img {
      max-width: 47px;
    }
  }
  .logger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 390px;
    padding-bottom: 55px;
    padding-top: 32px;
    background-color: rgb(50, 39, 87);
    .logger-form {
      display: flex;
      flex-direction: column;
      width: 70%;
      .login,
      .password {
      }
      .login::placeholder,
      .password::placeholder {
        color: #b8c3e0;
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
      }
      .tirscript-input {
        margin-bottom: 0;
      }
      .tirscript-input input:focus {
        cursor: unset;
      }
      .tirscript-input .form-control {
        background-color: inherit;
        border: 1px solid #514281;
        font-size: 14px;
        color: #b8c3e0;
        padding-left: 40px;
      }
      .login {
        // background-image: url("/assets/img/user-shape.svg");
        // background-repeat: no-repeat;
        // background-position-x: left;
        // background-position-y: center;
      }
      .password {
        // background-image: url("/assets/img/key.svg");
        // background-repeat: no-repeat;
        // background-position-x: left;
        // background-position-y: center;
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        a {
          color: white;
          text-decoration: underline;
        }
        .tirscript-button {
          width: 100px;
          height: 35px;
          background: rgba(99, 1, 87, 1);
          color: white;
          border: none;
        }
        .tirscript-button:hover {
          background: rgba(99, 1, 87, 0.5);
          transition: all 0.15s linear;
        }
      }
    }
  }
}
</style>
